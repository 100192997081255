import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SortButton from "../../common/SortButton";
import { SortedTable } from "../../common/SortedTable";
import { handleError } from "../../../api/apiUtils";



// Icons
import DownloadIcon from "../../common/icons/downloadicon.svg";
import ValidUnkw from "../../common/icons/verifiedunkw.svg";
import ValidOk from "../../common/icons/verifiedok.svg";
import ValidNok from "../../common/icons/verifiednok.svg";
import Validintok from "../../common/icons/verifiedIntok.svg";
import ValidintNok from "../../common/icons/verifiedIntnok.svg";

// Time
import moment from "moment";
import { useContextMenu } from "react-contexify";

const TachoDownloadList = ({ auth, tachoDownloads }) => {
    const { show } = useContextMenu({ id: "TachoMassDownload" });
    const { list, requestSort, sortConfig } = SortedTable(tachoDownloads);

    const downloadFile = (id, fileName) => {
    const baseUrl = process.env.API_URL + "/api/rtddata/";
    const uri = baseUrl + id;

    fetch(uri, {
        headers: {
            Authorization: auth.accessToken,
        },
    })
        .then((res) => res.blob())
        .then((blob) => {
            const href = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((error) => handleError(error));
};

    return (
        <div className="tachoDownload">
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        {auth.user.IsReseller == false ? (
                            <></>
                        ) : (
                            <th>
                                Customer
                                <SortButton
                                    name="Customer"
                                    requestSort={requestSort}
                                    sortConfig={sortConfig}
                                />
                            </th>
                        )}

                        <th>
                            Upload Date
                            <SortButton
                                name="TimeStampUpload"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Periode
                            <SortButton
                                name="TimeStampFrom"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            Card Number
                            <SortButton
                                name="CardNumber"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            IMEI
                            <SortButton
                                name="IMEI"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            LicensePlate
                            <SortButton
                                name="LicensePlate"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th>
                            VIN
                            <SortButton
                                name="VIN"
                                requestSort={requestSort}
                                sortConfig={sortConfig}
                            />
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {list === undefined ? (
                        <tr>
                            <td colSpan="15" style={{ textAlign: "center" }}>
                                No tacho downloads found
                            </td>
                        </tr>
                    ) : (
                        list.map((tachoDownload) => {
                            return (
                                <tr
                                    onContextMenu={(e) => {
                                        show({ event: e, props: { tachoDownload: tachoDownload } });
                                    }}
                                    className="table-row"
                                    id={tachoDownload.Id}
                                    key={tachoDownload.Id}
                                >
                                    {auth.user.IsReseller == false ? (
                                        <></>
                                    ) : (
                                        <td>{tachoDownload.Customer}</td>
                                    )}

                                    <td>
                                        {moment(tachoDownload.TimeStampUpload).format(
                                            "DD-MM-YYYY HH:mm:ss"
                                        )}
                                    </td>
                                    <td>
                                        {tachoDownload.TimeStampFrom !== "1990-01-01T00:00:00Z" ? (
                                            <>
                                                {moment(tachoDownload.TimeStampFrom).format(
                                                    "DD-MM-YYYY HH:mm"
                                                )}{" "}
                                                <> -</>
                                                {moment(tachoDownload.TimeStampTo).format(
                                                    "DD-MM-YYYY HH:mm"
                                                )}
                                            </>
                                        ) : (
                                            <>Unknown Periode</>
                                        )}
                                    </td>
                                    <td>{tachoDownload.CardNumber}</td>
                                    <td>{tachoDownload.IMEI}</td>
                                    <td>
                                        {tachoDownload.LicensePlate == null ? (
                                            <>
                                                {" "}
                                                <i>Name: </i> {tachoDownload.Name}{" "}
                                            </>
                                        ) : (
                                            <>{tachoDownload.LicensePlate}</>
                                        )}
                                    </td>
                                    <td>{tachoDownload.VIN}</td>
                                    <td>
                                        {tachoDownload.ValidState == 1 ? (
                                            <img src={ValidOk}></img>
                                        ) : tachoDownload.ValidState == 2 ? (
                                            <img src={ValidNok}></img>
                                        ) : tachoDownload.ValidState == 3 ? (
                                            <img src={ValidNok}></img>
                                        ) : tachoDownload.ValidState == 11 ? (
                                            <img src={Validintok}></img>
                                        ) : tachoDownload.ValidState == 12 ? (
                                            <img src={ValidintNok}></img>
                                        ) : tachoDownload.ValidState == 13 ? (
                                            <img src={ValidintNok}></img>
                                        ) : tachoDownload.ValidState == 44 ? (
                                            <img src={ValidNok}></img>
                                        ) : (
                                            <img src={ValidUnkw}></img>
                                        )}
                                        <img
                                            src={DownloadIcon}
                                            alt="Download"
                                            onClick={() => downloadFile(tachoDownload.Id, tachoDownload.FileName)}
                                        />


                                    </td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
        </div>
    );
};

TachoDownloadList.propTypes = {
    auth: PropTypes.object.isRequired,
    tachoDownloads: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TachoDownloadList);
