export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const CHECK_END_OF_LIST = "CHECK_END_OF_LIST";

export const LOAD_DEVICE_TYPES_SUCCESS = "LOAD_DEVICE_TYPES_SUCCESS";
export const LOAD_DEVICES_SUCCESS = "LOAD_DEVICES_SUCCESS";
export const LOAD_ENDPOINT_SUCCESS = "LOAD_ENDPOINT_SUCCESS";
export const LOAD_ENDPOINTS_SUCCESS = "LOAD_ENDPOINTS_SUCCESS";
export const LOAD_CUSTOMERS_SUCCESS = "LOAD_CUSTOMERS_SUCCESS";

export const LOAD_DASHBOARD_SUCCESS = "LOAD_DASHBOARD_SUCCESS";

export const LOAD_SEARCH_RESULT_DEVICES_SUCCESS =
  "LOAD_SEARCH_RESULT_DEVICES_SUCCESS";
export const LOAD_DEVICE_DETAILS_SUCCESS = "LOAD_DEVICE_DETAILS_SUCCESS";
export const NEW_RAWMESSAGE_DISPATCHED_TO_STATE = "NEW_RAWMESSAGE_DISPATCHED_TO_STATE";
export const LOAD_DEVICE_STATE_SUCCESS = "LOAD_DEVICE_STATE_SUCCESS";
export const LOAD_DEVICE_RAW_MESSAGES_SUCCESS =
  "LOAD_DEVICE_RAW_MESSAGES_SUCCESS";
export const LOAD_FIRST_BATCH_DEVICE_RAW_MESSAGES_SUCCESS =
  "LOAD_FIRST_BATCH_DEVICE_RAW_MESSAGES_SUCCESS";
export const LOAD_BATCH_DEVICE_RAW_MESSAGES_SUCCESS =
  "LOAD_BATCH_DEVICE_RAW_MESSAGES_SUCCESS";
export const LOAD_DEVICE_CURRENT_CONFIG_SUCCESS =
  "LOAD_DEVICE_CURRENT_CONFIG_SUCCESS";

export const LOAD_FIRST_BATCH_DEVICES_SUCCESS =
  "LOAD_FIRST_BATCH_DEVICES_SUCCESS";
export const LOAD_BATCH_DEVICES_SUCCESS = "LOAD_BATCH_DEVICES_SUCCESS";

export const LOAD_DEVICE_SETTINGS_SUCCESS = "LOAD_DEVICE_SETTINGS_SUCCESS";
export const SAVE_DEVICE_SETTINGS_SUCCESS = "SAVE_DEVICE_SETTINGS_SUCCESS";
export const UPDATE_DEVICE_SETTING_SUCCESS = "UPDATE_DEVICE_SETTING_SUCCESS";
export const REMOVE_DEVICE_SETTING_SUCCESS = "REMOVE_DEVICE_SETTING_SUCCESS";
export const REMOVE_UNPROCESSED_DEVICE_SETTINGS_SUCCESS =
  "REMOVE_UNPROCESSED_DEVICE_SETTINGS_SUCCESS";

export const LOAD_DEVICE_MODEM_REQUESTS_SUCCESS =
  "LOAD_DEVICE_MODEM_REQUESTS_SUCCESS";
export const SAVE_DEVICE_MODEM_REQUEST_SUCCESS =
  "SAVE_DEVICE_MODEM_REQUEST_SUCCESS";
export const UPDATE_DEVICE_MODEM_REQUEST_SUCCESS =
  "UPDATE_DEVICE_MODEM_REQUEST_SUCCESS";
export const REMOVE_DEVICE_MODEM_REQUEST_SUCCESS =
  "REMOVE_DEVICE_MODEM_REQUEST_SUCCESS";

export const LOAD_DEVICE_ENDPOINTS_SUCCESS = "LOAD_DEVICE_ENDPOINTS_SUCCESS";
export const SAVE_DEVICE_ENDPOINT_SUCCESS = "SAVE_DEVICE_ENDPOINT_SUCCESS";
export const UPDATE_DEVICE_ENDPOINT_SUCCESS = "UPDATE_DEVICE_ENDPOINT_SUCCESS";
export const REMOVE_DEVICE_ENDPOINT_SUCCESS = "REMOVE_DEVICE_ENDPOINT_SUCCESS";
export const LOAD_SEARCH_RESULT_ENDPOINT_SUCCESS =
  "LOAD_SEARCH_RESULT_ENDPOINT_SUCCESS";

export const LOAD_SEARCH_RESULT_RAW_MESSAGES_SUCCESS =
  "LOAD_SEARCH_RESULT_RAW_MESSAGES_SUCCESS";
export const LOAD_FIRST_BATCH_RAW_MESSAGES_SUCCESS =
  "LOAD_FIRST_BATCH_RAW_MESSAGES_SUCCESS";
export const LOAD_BATCH_RAW_MESSAGES_SUCCESS =
  "LOAD_BATCH_RAW_MESSAGES_SUCCESS";

export const LOAD_RTDINTEGRATIONS_SUCCESS = "LOAD_RTDINTEGRATIONS_SUCCESS";

export const LOAD_SEARCHED_LOGS_SUCCESS = "LOAD_SEARCHED_LOGS_SUCCESS";
export const LOAD_FIRST_BATCH_LOGS_SUCCESS = "LOAD_FIRST_BATCH_LOGS_SUCCESS";
export const LOAD_BATCH_LOGS_SUCCESS = "LOAD_BATCH_LOGS_SUCCESS";

export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";

export const LOAD_CUSTOMER_DETAILS_SUCCESS = "LOAD_CUSTOMER_DETAILS_SUCCESS";
export const LOAD_CUSTOMER_DEVICES_SUCCESS = "LOAD_CUSTOMER_DEVICES_SUCCESS";
export const SAVE_CUSTOMER_SUCCESS = "SAVE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const REMOVE_CUSTOMER_SUCCESS = "REMOVE_CUSTOMER_SUCCESS";

export const LOAD_DEVICE_CUSTOMER_HISTORY_SUCCESS =
  "LOAD_DEVICE_CUSTOMER_HISTORY_SUCCESS";

export const SAVE_CUSTOMER_DEVICES_SUCCESS = "SAVE_CUSTOMER_DEVICES_SUCCESS";
export const REMOVE_CUSTOMER_DEVICES_SUCCESS =
  "REMOVE_CUSTOMER_DEVICES_SUCCESS";

export const SAVE_CONFIGLIST_SUCCESS = "SAVE_CONFIGLIST_SUCCESS";

export const LOAD_CONFIG_TEMPLATES_SUCCESS = "LOAD_CONFIG_TEMPLATES_SUCCESS";
export const SAVE_CONFIG_TEMPLATE_SUCCESS = "SAVE_CONFIG_TEMPLATE_SUCCESS";
export const REMOVE_CONFIG_TEMPLATE_SUCCESS = "REMOVE_CONFIG_TEMPLATE_SUCCESS";

export const LOAD_CONFIGURATION_GROUPS_SUCCESS =
  "LOAD_CONFIGURATION_GROUPS_SUCCESS";
export const LOAD_CONFIGURATION_GROUPS_CUSTOMER_SUCCESS =
  "LOAD_CONFIGURATION_GROUPS_CUSTOMER_SUCCESS";
export const SAVE_CONFIGURATION_GROUP_SUCCESS =
  "SAVE_CONFIGURATION_GROUP_SUCCESS";
export const UPDATE_CONFIGURATION_GROUP_SUCCESS =
  "UPDATE_CONFIGURATION_GROUP_SUCCESS";
export const REMOVE_CONFIGURATION_GROUP_SUCCESS =
  "REMOVE_CONFIGURATION_GROUP_SUCCESS";
export const LOAD_SEARCH_RESULT_CONFIGURATION_GROUP_SUCCESS =
  "LOAD_SEARCH_RESULT_CONFIGURATION_GROUP_SUCCESS";
export const LOAD_SEARCH_RESULT_AUTHENTICATION_SERVER_SUCCESS =
  "LOAD_SEARCH_RESULT_AUTHENTICATION_SERVER_SUCCESS";

export const LOAD_FEATURE_SUCCESS = "LOAD_FEATURE_SUCCESS";
export const LOAD_FEATURES_SUCCESS = "LOAD_FEATURES_SUCCESS";
export const SAVE_FEATURE_SUCCESS = "SAVE_FEATURE_SUCCESS";
export const UPDATE_FEATURE_SUCCESS = "UPDATE_FEATURE_SUCCESS";
export const REMOVE_FEATURE_SUCCESS = "REMOVE_FEATURE_SUCCESS";

export const LOAD_ROLE_SUCCESS = "LOAD_ROLE_SUCCESS";
export const LOAD_ROLES_SUCCESS = "LOAD_ROLES_SUCCESS";
export const SAVE_ROLE_SUCCESS = "SAVE_ROLE_SUCCESS";
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const REMOVE_ROLE_SUCCESS = "REMOVE_ROLE_SUCCESS";

export const UPDATE_ACCOUNT_CUSTOMER_SUCCESS =
  "UPDATE_ACCOUNT_CUSTOMER_SUCCESS";
export const LOAD_ACCOUNT_SUCCESS = "LOAD_ACCOUNT_SUCCESS";
export const SAVE_ACCOUNT_USER_SUCCESS = "SAVE_ACCOUNT_USER_SUCCESS";
export const UPDATE_ACCOUNT_USER_SUCCESS = "UPDATE_ACCOUNT_USER_SUCCESS";
export const REMOVE_ACCOUNT_USER_SUCCESS = "REMOVE_ACCOUNT_USER_SUCCESS";

export const LOAD_CUSTOMER_USERS_SUCCESS = "LOAD_CUSTOMER_USERS_SUCCESS";
export const SAVE_CUSTOMER_USER_SUCCESS = "SAVE_CUSTOMER_USER_SUCCESS";
export const UPDATE_CUSTOMER_USER_SUCCESS = "UPDATE_CUSTOMER_USER_SUCCESS";
export const REMOVE_CUSTOMER_USER_SUCCESS = "REMOVE_CUSTOMER_USER_SUCCESS";

export const SAVE_DEVICE_CUSTOMER_CONNECTIONS_SUCCESS =
  "SAVE_DEVICE_CUSTOMER_CONNECTIONS_SUCCESS";
export const SAVE_DEVICE_CUSTOMER_CONNECTION_SUCCESS =
  "SAVE_DEVICE_CUSTOMER_CONNECTION_SUCCESS";

export const LOAD_AUTHENTICATION_SERVER_DETAILS_SUCCESS =
  "LOAD_AUTHENTICATION_SERVER_DETAILS_SUCCESS";

export const LOAD_AUTHENTICATION_SERVERS_SUCCESS =
  "LOAD_AUTHENTICATION_SERVERS_SUCCESS";
export const LOAD_AUTHENTICATION_SERVER_SUCCESS =
  "LOAD_AUTHENTICATION_SERVER_SUCCESS";
export const SAVE_AUTHENTICATION_SERVER_SUCCESS =
  "SAVE_AUTHENTICATION_SERVER_SUCCESS";
export const UPDATE_AUTHENTICATION_SERVER_SUCCESS =
  "UPDATE_AUTHENTICATION_SERVER_SUCCESS";
export const REMOVE_AUTHENTICATION_SERVER_SUCCESS =
  "REMOVE_AUTHENTICATION_SERVER_SUCCESS";

export const SAVE_DEVICE_CONFIGURATION_GROUP_SUCCESS =
  "SAVE_DEVICE_CONFIGURATION_GROUP_SUCCESS";
export const REMOVE_DEVICE_CONFIGURATION_GROUP_SUCCESS =
  "REMOVE_DEVICE_CONFIGURATION_GROUP_SUCCESS";
export const SAVE_DEVICES_CONFIGURATION_GROUP_SUCCESS =
  "SAVE_DEVICE_CONFIGURATION_GROUP_SUCCESS";
export const REMOVE_DEVICES_CONFIGURATION_GROUP_SUCCESS =
  "REMOVE_DEVICE_CONFIGURATION_GROUP_SUCCESS";
export const SAVE_CONFIGURATION_GROUP_FOR_DEVICE_SUCCESS =
  "SAVE_CONFIGURATION_GROUP_FOR_DEVICE_SUCCESS";

export const LOAD_COMPANY_CARD_SUCCESS = "LOAD_COMPANY_CARD_SUCCESS";
export const SAVE_COMPANY_CARD_SUCCESS = "SAVE_COMPANY_CARD_SUCCESS";
export const UPDATE_COMPANY_CARD_SUCCESS = "UPDATE_COMPANY_CARD_SUCCESS";
export const REMOVE_COMPANY_CARD_SUCCESS = "REMOVE_COMPANY_CARD_SUCCESS";

export const LOAD_TACHO_DOWNLOAD_SUCCESS = "LOAD_TACHO_DOWNLOAD_SUCCESS";
export const SAVE_TACHO_DOWNLOAD_SUCCESS = "SAVE_TACHO_DOWNLOAD_SUCCESS";
export const UPDATE_TACHO_DOWNLOAD_SUCCESS = "UPDATE_TACHO_DOWNLOAD_SUCCESS";
export const REMOVE_TACHO_DOWNLOAD_SUCCESS = "REMOVE_TACHO_DOWNLOAD_SUCCESS";
export const FILE_TACHO_DOWNLOAD_SUCCESS = "FILE_TACHO_DOWNLOAD_SUCCESS";
export const LOAD_SEARCHED_TACHO_DOWNLOADS_SUCCESS =
  "LOAD_SEARCHED_TACHO_DOWNLOADS_SUCCESS";
export const LOAD_TACHO_DOWNLOADS_BY_CUSTOMER_SUCCESS =
  "LOAD_TACHO_DOWNLOADS_BY_CUSTOMER_SUCCESS";
export const LOAD_COUNT_TACHO_DOWNLOADS_SUCCESS =
  "LOAD_COUNT_TACHO_DOWNLOADS_SUCCESS";

export const LOAD_CUSTOM_TEST_DATA_DETAILS_SUCCESS =
  "LOAD_CUSTOM_TEST_DATA_DETAILS_SUCCESS";

export const LOAD_CUSTOM_TEST_DATA_SUCCESS = "LOAD_CUSTOM_TEST_DATA_SUCCESS";
export const SAVE_CUSTOM_TEST_DATA_SUCCESS = "SAVE_CUSTOM_TEST_DATA_SUCCESS";
export const UPDATE_CUSTOM_TEST_DATA_SUCCESS =
  "UPDATE_CUSTOM_TEST_DATA_SUCCESS";
export const REMOVE_CUSTOM_TEST_DATA_SUCCESS =
  "REMOVE_CUSTOM_TEST_DATA_SUCCESS";
export const DOWNLOAD_FILE_CUSTOM_TEST_DATA_SUCCESS =
  "DOWNLOAD_FILE_CUSTOM_TEST_DATA_SUCCESS";

export const SAVE_ENDPOINT_SUCCESS = "SAVE_ENDPOINT_SUCCESS";
export const UPDATE_ENDPOINT_SUCCESS = "UPDATE_ENDPOINT_SUCCESS";
export const REMOVE_ENDPOINT_SUCCESS = "REMOVE_ENDPOINT_SUCCESS";

export const LOAD_PROTOCOLS_SUCCESS = "LOAD_PROTOCOLS_SUCCESS";
export const LOAD_MESSAGE_TYPES_SUCCESS = "LOAD_MESSAGE_TYPES_SUCCESS";

export const LOAD_DEVICESTESTS_SUCCESS = "LOAD_DEVICESTESTS_SUCCESS";
export const LOAD_DEVICESTEST_SUCCESS = "LOAD_DEVICESTEST_SUCCESS";
export const SAVE_DEVICETEST_SUCCES = "SAVE_DEVICETEST_SUCCES";

export const SAVE_DEVICETESTSETTINGS_SUCCESS = "SAVE_DEVICETESTSETTINGS_SUCCESS";
export const UPDATE_DEVICETESTSETTINGS_SUCCESS = "UPDATE_DEVICETESTSETTINGS_SUCCESS";
export const GET_DEVICESETTINGS_SUCCESS = "GET_DEVICESETTINGS_SUCCESS";
export const GET_ALL_DEVICETESTSETTINGS_SUCCESS = "GET_ALL_DEVICETESTSETTINGS_SUCCESS";
export const SET_DEVICETEST_ACTIVE = "SET_DEVICETEST_ACTIVE";
export const SET_DEVICETEST_INACTIVE = "SET_DEVICETEST_INACTIVE";

export const LOAD_CANCONFIGS_SUCCESS = "LOAD_CANCONFIGS_SUCCESS";
export const LOAD_ACTIVECANCONFIGS_SUCCESS = "LOAD_ACTIVECANCONFIGS_SUCCESS";
export const LOAD_CANCONFIG_SUCCESS = "LOAD_CANCONFIG_SUCCESS";
export const UPDATE_CANCONFIG_SUCCESS = "UPDATE_CANCONFIG_SUCCESS";
export const SAVE_CANCONFIG_SUCCESS = "SAVE_CANCONFIG_SUCCESS";
export const MASS_UPDATE_CANCONFIG_SUCCESS = "MASS_UPDATE_CANCONFIG_SUCCESS";

export const LOAD_FIRMWARES_SUCCESS = "LOAD_FIRMWARES_SUCCESS";
export const LOAD_ACTIVEFIRMWARES_SUCCESS = "LOAD_ACTIVEFIRMWARES_SUCCESS";
export const LOAD_FIRMWARE_SUCCESS = "LOAD_FIRMWARE_SUCCESS";
export const UPDATE_FIRMWARE_SUCCESS = "UPDATE_FIRMWARE_SUCCESS";
export const SAVE_FIRMWARE_SUCCESS = "SAVE_FIRMWARE_SUCCESS";
export const LOAD_FIRMWAREFLAGS_SUCCESS = "LOAD_FIRMWAREFLAGS_SUCCESS";
export const DELETE_FIRMWARE_SUCCESS = "DELETE_FIRMWARE_SUCCESS";

export const LOAD_TACHO_OBJECT_SUCCESS = "LOAD_TACHO_OBJECT_SUCCESS";
export const LOAD_TACHO_DRIVER_SUCCESS = "LOAD_TACHO_DRIVER_SUCCESS";
export const SAVE_DEVICE_NOTE_SUCCESS = "SAVE_DEVICE_NOTE_SUCCESS";

export const LOAD_DEVICE_MESSAGES_SUCCESS = "LOAD_DEVICE_MESSAGES_SUCCESS";
export const SAVE_DEVICE_MESSAGES_SUCCESS = "SAVE_DEVICE_MESSAGES_SUCCESS";


export const LOAD_SETTINGS_PROFILES_SUCCESS = "LOAD_SETTINGS_PROFILES_SUCCESS"
export const SAVE_SETTINGS_PROFILE_SUCCESS = "SAVE_SETTINGS_PROFILE_SUCCESS"
export const DELETE_SETTINGS_PROFILE_SUCCESS = "DELETE_SETTINGS_PROFILE_SUCCESS"
export const UPDATE_SETTINGS_PROFILE_SUCCESS = "UPDATE_SETTINGS_PROFILE_SUCCESS"
export const MASS_UPDATE_SUCCESS = "MASS_UPDATE_SUCCESS"
